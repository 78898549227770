<template>
  <modal body-classes="pt-0 pb-0" @close="closeLayer">
    <h6 slot="header" class="modal-title">발신자번호 {{ isModifyMode ? '수정' : '등록' }}</h6>

    <template>
      <form role="form" class="needs-validation">
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">전화번호 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="form.senderNo"
                        name="telNo"
                        :error="_getError('telNo')"
                        :maxlength="13"
                        v-validate="'required'"
                        @keyup="phoneMask"
                        :disabled="isModifyMode"
                        placeholder="전화번호 입력">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">설명</label>
          <div class="col-md-9">
            <base-input>
              <textarea class="form-control" v-model="form.description" placeholder="설명 입력" rows="4" resize="none"></textarea>
            </base-input>
          </div>
        </div>
      </form>
    </template>

    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
      <base-button type="primary" class="ml-auto" @click="processRegister">{{ isModifyMode ? '수정' : '등록' }}</base-button>
    </template>
  </modal>
</template>

<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX, RESULT_CODE } from '@/shared/util/const';

  export default {
    inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
    name: 'CallerIdLayer',
    mixins: [ commJsUtilMixin, commValidatorMixin ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        form: {
          senderNo: '',
          description: '',
          status: ''
        },
        isModifyMode: false
      }
    },
    props: {
      senderNo: {
        type: String,
        default: undefined,
        description: '발신자번호'
      },
    },
    created() {
      if(this.senderNo) {
        // 수정모드 활성화
        this.isModifyMode = true;
        // 상세정보 조회
        this.getInfo();
      }
    },
    methods: {
      /**
       * 레이어 닫기 이벤트 실행
       */
      closeLayer() {
        this.$emit('close');
      },
      /**
       * 발신자번호 등록 실행
       */
      register() {
        this._swalQuestion({ title:'등록 하시겠습니까?' }, () => {
          this.$axios.post(`${USER_API_PREFIX}/caller-id`, {
            senderNo: this.form.senderNo,
            description: this.form.description
          }).then(res => {
            if (res.data.result.code === RESULT_CODE.SUCCESS) {
              this.$emit('close');
              this.notify('default', res.data.result.message);
            } else {
              this.notify('warning', res.data.result.message);
            }
          });
        });
      },
      /**
       * 발신자번호 수정 실행
       */
      modify() {
        this._swalQuestion({ title:'수정 하시겠습니까?' }, () => {
          this.$axios.put(`${USER_API_PREFIX}/caller-id`, {
            senderNo: this.form.senderNo,
            description: this.form.description
          }).then(res => {
            if (res.data.result.code === RESULT_CODE.SUCCESS) {
              this.$emit('close');
              this.notify('default', res.data.result.message);
            } else {
              this.notify('warning', res.data.result.message);
            }
          });
        });
      },
      /**
       * 상세정보 조회
       */
      getInfo() {
        this.$axios.get(`${USER_API_PREFIX}/caller-id/info`, {
          params: {
            senderNo: this.senderNo
          }
        }).then(res => {
          let callerInfo = res.data.result.callerInfo;
          this.form.senderNo = this._autoHyphenPhoneNumber(callerInfo.senderNo);
          this.form.description = callerInfo.description;
          this.form.status = callerInfo.status;
        });
      },
      /**
       * 등록 실행 전, validate check!
       */
      processRegister() {
        if(this.isModifyMode) {
          this._validateBeforeSubmit(this.modify);
        } else {
          this._validateBeforeSubmit(this.register);
        }
      },
      /**
       * 키 이벤트로 핸드폰 번호 형식으로 문자열 변경
       */
      phoneMask() {
        this.form.senderNo = this._autoHyphenPhoneNumber(this.form.senderNo);
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      }
    }
  };
</script>

<style>
</style>
