<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-white d-inline-block mb-0">발신자번호 관리</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">발신자번호 목록</h3>
            <div class="float-right">
              <base-button type="primary" block @click="openLayer">발신자번호 등록</base-button>
            </div>
          </template>
          <!-- 발신자번호 레이어 -->
          <caller-id-layer v-if="showLayer"
                           :sender-no="senderNo"
                           @close="closeLayer">
          </caller-id-layer>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <base-input additional-classes="width-100">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </base-input>

              <base-input>
                <el-select
                  v-model="search.status"
                  filterable
                  @change="getList(1)"
                  placeholder="상태 조건">
                  <el-option v-for="option in statusOptions"
                             :key="option.label"
                             :label="option.label"
                             :value="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <el-table :data="callerList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'등록된 발신자번호가 없습니다.'"
                      class="table-responsive table-flush">
              <el-table-column prop="status" label="상태" min-width="10%">
                <template slot-scope="scope">
                  <badge class="badge-dot mr-4" type="">
                    <i :class="statusClass(`${scope.row.status}`)"></i>
                    <span class="status">{{ scope.row.status | statusFilter }}</span>
                  </badge>
                </template>
              </el-table-column>
              <el-table-column prop="senderNo" label="발신자번호" min-width="20%">
                <template slot-scope="scope">
                  <div class="align-items-center">
                    <a href="#" @click="openModifyLayer(scope.row.senderNo)"><u>{{ scope.row.senderNo | phoneNumberFormat }}</u></a>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="description" label="설명" min-width="30%">
                <template slot-scope="scope">{{ scope.row.description | nullToHyphen }}</template>
              </el-table-column>
              <el-table-column prop="regBy" label="등록자" min-width="20%"></el-table-column>
              <el-table-column prop="regDate" label="등록일시" min-width="20%">
                <template slot-scope="scope">{{ scope.row.regDate | convertDatetimeFormat }}</template>
              </el-table-column>
            </el-table>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="getList">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE } from '@/shared/util/const';
  import CallerIdLayer from './CallerIdLayer';

  export default {
    mixins: [
      commPaginationMixin
    ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      CallerIdLayer
    },
    computed: {
      ...mapGetters({
        session: 'SessionStore/getSession'
      }),
    },
    filters: {
      /**
       * 상태 코드로 코드명 반환
       */
      statusFilter: value => {
        let statusName = '';
        switch (value) {
          case 'A':
            statusName = '승인';
            break;
          case 'U':
            statusName = '미승인';
            break;
        }
        return statusName;
      }
    },
    created() {
      this.getList(1);
    },
    data() {
      return {
        showLayer: false,
        callerList: [],
        senderNo: undefined,
        search: {
          status: ''
        },
        statusOptions: [
          {
            label: '== 상태 선택 ==',
            value: ''
          }, {
            label: '승인',
            value: 'A'
          }, {
            label: '미승인',
            value: 'U'
          }
        ]
      };
    },
    methods: {
      /**
       * 등록 레이어 생성
       */
      openLayer() {
        this.showLayer = true;
      },
      /**
       * 수정 레이어 생성
       */
      openModifyLayer(senderNo) {
        this.showLayer = true;
        this.senderNo = senderNo;
      },
      /**
       * 레이어 닫기
       */
      closeLayer() {
        this.showLayer = false;
        this.senderNo = undefined;
        this.getList();
      },
      /**
       * 목록 조회
       */
      getList(page) {
        let pageNo = page ? page : this.pagination.currentPage;
        this.$axios.get(`${USER_API_PREFIX}/caller-id`, {
          params: {
            page: pageNo,
            rows: this.pagination.perPage,
            status: this.search.status
          }
        }).then(res => {
          this.callerList = res.data.result.callerList; // 목록정보 세팅
          this.pagination.currentPage = pageNo; // 페이지 번호 세팅
          this.pagination.total = res.data.result.total; // 전체페이지수 세팅
        });
      },
      /**
       * 상태 클래스 반환
       * @param value
       * @returns {string}
       */
      statusClass(value) {
        let statusClass = '';
        switch (value) {
          case 'A':
            statusClass = 'bg-success';
            break;
          case 'U':
            statusClass = 'bg-warning';
            break;
        }
        return statusClass;
      }
    }
  };
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
